import React from "react";
import Texty from "rc-texty";

export const Loading = () => {
  return (
    <div
      className="app"
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <div
        className="loading"
        style={{
          fontSize: 30,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src="/loading.gif" alt="loading" width="120px" />

        <Texty style={{ fontSize: 14 }}>loading...</Texty>
        {/* <ClipLoader
          size={10}
          color={"#000000"}
          loading={true}
        /> */}
      </div>
    </div>
  );
};

import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./index.css";
import "animate.css";
import App from "./App";
import Select from "./Select";
import Broker from "./Broker";
import Location from "./Location";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "mobx-react";
import { Store } from "./store";
import Builds from "./Builds";
import { appConfig } from "./config";

const appStore = new Store();

ReactDOM.render(
  <Provider AppStore={appStore}>
    {appConfig.locale.has_location ? (
      <Router>
        <Switch>
          <Route path="/:location/build/:slug/:number" exact component={App} />
          <Route path="/:location/build/:slug" exact component={App} />
          <Route path="/:location/builds" component={Builds} />
          <Route path="/:location/account" exact component={Broker} />
          <Route path="/:location" exact component={Select} />
          <Route path="/" exact component={Location} />
        </Switch>
      </Router>
    ) : (
      <Router>
        <Route path="/build/:slug/:number" exact component={App} />
        <Route path="/build/:slug" exact component={App} />
        <Route path="/builds" exact component={Builds} />
        <Route path="/account" exact component={Broker} />
        <Route path="/" exact component={Select} />
      </Router>
    )}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

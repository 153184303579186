import { inject, observer } from "mobx-react";
import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { appConfig } from "../config";
import { convertCurrencyLocation } from "../helpers";
import "./DependencyPopup.css";

@inject("AppStore")
@observer
class DependencyPopup extends React.Component {
  store = this.props.AppStore;

  componentDidMount() {}

  onClose = (e) => {
    e.stopPropagation();
    this.store.popupClose();
  };

  render() {
    if (!this.store.dependencyPopup.visible) return <React.Fragment />;

    return (
      <div className="dependency" onClick={this.onClose}>
        <div
          className="dependency__wrapper animate__animated animate__zoomIn"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="dependency__header">
            <div className="dependecy__title">Option Required!</div>
            <div className="dependecy__close" onClick={this.onClose}>
              <AiOutlineClose fontSize="32" />
            </div>
          </div>
          <div className="dependency__content">
            <div className="dependency__dependant">
              <div className="dependency__option" style={{ cursor: "auto" }}>
                {this.store.dependencyPopup.dependant.picture && (
                  <div
                    className="dependency__option--image"
                    style={{ marginRight: 15 }}
                  >
                    <img
                      alt=""
                      src={`${appConfig.api}${this.store.dependencyPopup.dependant.picture.url}`}
                    />
                  </div>
                )}

                <div className="dependency__option--title">
                  {this.store.dependencyPopup.dependant.title}
                </div>
              </div>

              <div className="dependency__info">
                To select this option, you should select one of the required
                options below.
              </div>
            </div>
            <div className="dependency__dependencies">
              {this.store.dependencyPopup.dependencies.map((dependency, i) => (
                <div
                  className="dependency__option"
                  key={i}
                  onClick={() => this.store.selectDependency(dependency)}
                >
                  {dependency.picture && (
                    <div className="dependency__option--image">
                      <img
                        alt=""
                        src={`${appConfig.api}${dependency.picture.url}`}
                      />
                    </div>
                  )}

                  <div className="dependency__option--title">
                    {dependency.title}
                  </div>
                  {dependency.description && (
                    <div className="dependency__option--description">
                      <pre>{dependency[this.store.langify("description")]}</pre>
                    </div>
                  )}
                  <div className="dependency__option--price">
                    {convertCurrencyLocation(
                      dependency.price,
                      this.store.location
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DependencyPopup;

const locale = {
  local: {
    key: "local",
    name: "Onda Boats",
    multi_language: false,
    has_payment: false,
    culture: "en-US",
    currency: "eur",
    symbol: "€",
    lang: "en",
    api: "http://localhost:8000",
    url: "http://localhost:3000",
    has_location: false,
  },
  live: {
    key: "live",
    name: "Onda Boats",
    multi_language: false,
    has_payment: false,
    culture: "en-US",
    currency: "eur",
    symbol: "€",
    lang: "en",
    api: "https://api.riginos-onda.bilisimatolyesi.com",
    url: "https://onda.riginos.com",
    has_location: false,
  },
};

let { REACT_APP_LOCALE } = process.env;
if (!REACT_APP_LOCALE) REACT_APP_LOCALE = "local";

const selected_locale = locale[REACT_APP_LOCALE];

export const appConfig = {
  api: selected_locale.api,
  locale: selected_locale,
};
